<script setup lang="ts">
import { computed } from 'vue'
import iconProps, { tailwindMerge } from '../iconprops'
const props = defineProps(iconProps)
const defaultClasses = 'w-full h-full'
const mergedClasses = computed(() => tailwindMerge(defaultClasses, props.class))
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" :class="mergedClasses">
    <mask
      id="mask0_293_3608"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_293_3608)">
      <path
        d="M12.025 21C11.7416 21 11.5041 20.9042 11.3125 20.7125C11.1208 20.5208 11.025 20.2833 11.025 20V18.85C10.275 18.6833 9.61664 18.3917 9.04997 17.975C8.4833 17.5583 8.02497 16.975 7.67497 16.225C7.5583 15.9917 7.55414 15.7458 7.66247 15.4875C7.7708 15.2292 7.96664 15.0417 8.24997 14.925C8.4833 14.825 8.72497 14.8292 8.97497 14.9375C9.22497 15.0458 9.41664 15.225 9.54997 15.475C9.8333 15.975 10.1916 16.3542 10.625 16.6125C11.0583 16.8708 11.5916 17 12.225 17C12.9083 17 13.4875 16.8458 13.9625 16.5375C14.4375 16.2292 14.675 15.75 14.675 15.1C14.675 14.5167 14.4916 14.0542 14.125 13.7125C13.7583 13.3708 12.9083 12.9833 11.575 12.55C10.1416 12.1 9.1583 11.5625 8.62497 10.9375C8.09164 10.3125 7.82497 9.55 7.82497 8.65C7.82497 7.56667 8.17497 6.725 8.87497 6.125C9.57497 5.525 10.2916 5.18333 11.025 5.1V4C11.025 3.71667 11.1208 3.47917 11.3125 3.2875C11.5041 3.09583 11.7416 3 12.025 3C12.3083 3 12.5458 3.09583 12.7375 3.2875C12.9291 3.47917 13.025 3.71667 13.025 4V5.1C13.6583 5.2 14.2083 5.40417 14.675 5.7125C15.1416 6.02083 15.525 6.4 15.825 6.85C15.975 7.06667 16.0041 7.30833 15.9125 7.575C15.8208 7.84167 15.6333 8.03333 15.35 8.15C15.1166 8.25 14.875 8.25417 14.625 8.1625C14.375 8.07083 14.1416 7.90833 13.925 7.675C13.7083 7.44167 13.4541 7.2625 13.1625 7.1375C12.8708 7.0125 12.5083 6.95 12.075 6.95C11.3416 6.95 10.7833 7.1125 10.4 7.4375C10.0166 7.7625 9.82497 8.16667 9.82497 8.65C9.82497 9.2 10.075 9.63333 10.575 9.95C11.075 10.2667 11.9416 10.6 13.175 10.95C14.325 11.2833 15.1958 11.8125 15.7875 12.5375C16.3791 13.2625 16.675 14.1 16.675 15.05C16.675 16.2333 16.325 17.1333 15.625 17.75C14.925 18.3667 14.0583 18.75 13.025 18.9V20C13.025 20.2833 12.9291 20.5208 12.7375 20.7125C12.5458 20.9042 12.3083 21 12.025 21Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
