<script setup lang="ts">
import type { TCompany } from '@tl-ui/components/job/JobCard.vue'

const viewport = useViewport()
const toLocalePath = useLocalePath()

const props = defineProps({
  company: {
    type: Object as PropType<TCompany>,
    default: undefined,
    required: false
  },
  logo: {
    type: Object as PropType<{
      url?: string
      width?: number | null
      height?: number | null
    }>,
    default: undefined,
    required: false
  },
  clickable: {
    type: Boolean,
    default: true
  }
})

const dimension = computed(() => {
  const maxHeight = viewport.isLessThan('lg') ? 80 : 100
  const maxWidth = viewport.isLessThan('lg') ? 120 : 120
  const height = Math.min(maxHeight, props.logo?.height || maxHeight)

  // if no sizes, return a square
  if (!props.logo?.width || !props.logo?.height)
    return {
      width: height,
      height
    }
  // compute the ratio
  const ratio = props.logo?.width / props.logo?.height
  const width = Math.round(height * ratio)

  if (width > maxWidth) {
    return {
      width: maxWidth,
      height: Math.round(maxWidth / ratio)
    }
  }

  return {
    width,
    height
  }
})
</script>

<template>
  <template v-if="props.logo?.url && props.company">
    <nuxt-picture
      v-if="!props.clickable"
      format="webp"
      background="transparent"
      :src="props.logo?.url"
      :alt="`${company?.name}-company-logo`"
      :width="dimension.width"
      :height="dimension.height"
      sizes="sm: 100vw md:200px"
      position="left top"
      loading="lazy"
      fetchpriority="low"
      decoding="async"
    />
    <a
      v-else
      class="cursor-pointer"
      :alt="props.company.name"
      :href="toLocalePath(props.company.to)"
      target="_blank"
    >
      <nuxt-picture
        format="webp"
        background="transparent"
        :src="props.logo?.url"
        :alt="`${company?.name}-company-logo`"
        :width="dimension.width"
        :height="dimension.height"
        sizes="sm: 100vw md:200px"
        position="left top"
        loading="lazy"
        fetchpriority="low"
        decoding="async"
      />
    </a>
  </template>
</template>
