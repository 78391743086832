<script setup lang="ts">
import { computed } from 'vue'
import iconProps, { tailwindMerge } from '../iconprops'
const props = defineProps(iconProps)
const defaultClasses = 'w-full h-full'
const mergedClasses = computed(() => tailwindMerge(defaultClasses, props.class))
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :class="mergedClasses">
    <path
      d="M6.71797 4.88874C6.71772 5.38967 6.51849 5.86999 6.1641 6.22403C5.80971 6.57806 5.32919 6.77682 4.82826 6.77657C4.32732 6.77632 3.84701 6.57708 3.49297 6.22269C3.13893 5.8683 2.94018 5.38779 2.94043 4.88685C2.94068 4.38592 3.13992 3.9056 3.49431 3.55156C3.8487 3.19753 4.32921 2.99877 4.83015 2.99902C5.33108 2.99927 5.8114 3.19851 6.16543 3.5529C6.51947 3.90729 6.71823 4.38781 6.71797 4.88874ZM6.77464 8.1752H2.99709V19.9989H6.77464V8.1752ZM12.7432 8.1752H8.9845V19.9989H12.7054V13.7943C12.7054 10.3378 17.2101 10.0168 17.2101 13.7943V19.9989H20.9404V12.5099C20.9404 6.68307 14.2731 6.90028 12.7054 9.76177L12.7432 8.1752Z"
      fill="currentColor"
    />
  </svg>
</template>
