<script setup lang="ts">
import { computed } from 'vue'
import iconProps, { tailwindMerge } from '../iconprops'
const props = defineProps(iconProps)
const defaultClasses = 'w-full h-full'
const mergedClasses = computed(() => tailwindMerge(defaultClasses, props.class))
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :class="mergedClasses">
    <mask
      id="mask0_29_324"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      :class="mergedClasses"
    >
      <rect width="24" height="24" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_29_324)">
      <path
        d="M8 6V4C8 3.45 8.19583 2.97917 8.5875 2.5875C8.97917 2.19583 9.45 2 10 2H14C14.55 2 15.0208 2.19583 15.4125 2.5875C15.8042 2.97917 16 3.45 16 4V6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H4C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V8C2 7.45 2.19583 6.97917 2.5875 6.5875C2.97917 6.19583 3.45 6 4 6H8ZM10 6H14V4H10V6ZM20 15H15V16C15 16.2833 14.9042 16.5208 14.7125 16.7125C14.5208 16.9042 14.2833 17 14 17H10C9.71667 17 9.47917 16.9042 9.2875 16.7125C9.09583 16.5208 9 16.2833 9 16V15H4V19H20V15ZM11 15H13V13H11V15ZM4 13H9V12C9 11.7167 9.09583 11.4792 9.2875 11.2875C9.47917 11.0958 9.71667 11 10 11H14C14.2833 11 14.5208 11.0958 14.7125 11.2875C14.9042 11.4792 15 11.7167 15 12V13H20V8H4V13Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
