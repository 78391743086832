export function getDayDifferenceFromToday(postedDate: string, maxDayDiff?: number) {
  const today = new Date()
  const postingDate = new Date(postedDate)
  const timeDifference = today.getTime() - postingDate.getTime()
  // Convert milliseconds to days
  let days = Math.floor(timeDifference / (1000 * 3600 * 24))
  if (maxDayDiff && days > maxDayDiff) {
    days = maxDayDiff
  }
  return days
}

export function getReadableDayDifferenceFromToday(t: any, postedDate: string, maxDayDiff?: number) {
  const dayDifference = getDayDifferenceFromToday(postedDate, maxDayDiff)
  return t('common.time.day', dayDifference)
}
