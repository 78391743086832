<script setup lang="ts">
import { computed } from 'vue'
import iconProps, { tailwindMerge } from '../iconprops'
const props = defineProps(iconProps)
const defaultClasses = 'w-full h-full'
const mergedClasses = computed(() => tailwindMerge(defaultClasses, props.class))
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" :class="mergedClasses">
    <mask
      id="mask0_2519_1585"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_2519_1585)">
      <path
        d="M5.25 17.25C4.8375 17.25 4.48438 17.1031 4.19063 16.8094C3.89688 16.5156 3.75 16.1625 3.75 15.75V2.25C3.75 1.8375 3.89688 1.48438 4.19063 1.19063C4.48438 0.896875 4.8375 0.75 5.25 0.75H12.75C13.1625 0.75 13.5156 0.896875 13.8094 1.19063C14.1031 1.48438 14.25 1.8375 14.25 2.25V15.75C14.25 16.1625 14.1031 16.5156 13.8094 16.8094C13.5156 17.1031 13.1625 17.25 12.75 17.25H5.25ZM5.25 15V15.75H12.75V15H5.25ZM5.25 13.5H12.75V4.5H5.25V13.5ZM5.25 3H12.75V2.25H5.25V3Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
