<script setup lang="ts">
import { computed } from 'vue'
import iconProps, { tailwindMerge } from '../iconprops'
const props = defineProps(iconProps)
const defaultClasses = 'w-full h-full'
const mergedClasses = computed(() => tailwindMerge(defaultClasses, props.class))
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :class="mergedClasses">
    <path
      d="M13.3 13.35H15.55L16.45 9.75H13.3V7.95C13.3 7.023 13.3 6.15 15.1 6.15H16.45V3.126C16.1566 3.0873 15.0487 3 13.8787 3C11.4352 3 9.7 4.4913 9.7 7.23V9.75H7V13.35H9.7V21H13.3V13.35Z"
      fill="currentColor"
    />
  </svg>
</template>
