<script setup lang="ts">
import { computed } from 'vue'
import iconProps, { tailwindMerge } from '../iconprops'
const props = defineProps(iconProps)
const defaultClasses = 'w-full h-full'
const mergedClasses = computed(() => tailwindMerge(defaultClasses, props.class))
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :class="mergedClasses">
    <g clip-path="url(#clip0_545_1534)">
      <path
        d="M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_545_1534">
        <rect width="24" height="24" fill="currentFill" />
      </clipPath>
    </defs>
  </svg>
</template>
